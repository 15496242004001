import React, { Component } from 'react'
import DeclarationBasic from './DeclarationBasic';
import DeclarationGP from './DeclarationGP';

export class DeclarationManager extends Component {

    constructor(props){
        super(props);

        this.state = { declaration: false};

    }

    confirmDeclarationStatus = (isOK, info) => {
        this.props.confirm(true);
    }
  

    render() {
        return (
            <div>
               
            <DeclarationGP setGP={this.confirmDeclarationStatus} />

            </div>
        )
    }
}

export default DeclarationManager
