import React, { Component } from 'react'
import DeclarationBasic from './DeclarationBasic';
import DeclarationManager from './DeclarationManager';
import ThemeManager from './ThemeManager';
import i18n from "i18next";

export class Compliance extends Component {


    intervalID = 0;

    constructor(props){
        super(props);

        this.state = { covid: false, showConfirm: false, error: false, animationShake: '', userInfo: props.user };

    }

    static getDerivedStateFromProps(props, current_state) {
        if (current_state.error !== props.error) {          
            return {
                error: props.error          
            }
        }      
        return null;
    }


    delcarationStatus = (isOK) => {
        this.setState({ showConfirm: isOK, covid: true}); 
    }


    confirmBook = (e) => {
        if(this.state.covid){
            this.props.confirmAccess();
        }else{
            this.setState({ animationShake: 'animationShake'}); 
            this.intervalID = setInterval(this.closeAnimation, 300);
        }      
    }

    render() {

        let themeManager = ThemeManager.getInstance();
    const _backgroundColor = themeManager.getBackgroundColor();
    const _selectionColor = themeManager.getSelectionColor();
    const _mainTextColor = themeManager.getMainTextColor();
    const _secondaryTextColor = themeManager.getSecondaryTextColor();
    const _itemBackgroundColor = themeManager.getItemBackgroundColor();
    const _buttonTextColor = themeManager.getButtonTextColor();
    const _errorColor = themeManager.getErrorColor();

        return (
            <div>
                <div style={{marginTop: '20px', marginBottom: '0px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: _secondaryTextColor, fontSize: '14px'}}>
                 {i18n.t(this.state.userInfo.welcome)} <p style={{ color: _selectionColor, marginLeft: '8px', marginRight: '8px', fontSize: '16px', fontWeight: 'bold'}}>{this.state.userInfo.name}</p>
            </div>
               

                <DeclarationBasic showConfirm={this.delcarationStatus} />
                    <br />

                {this.state.showConfirm && 
                <div><div  style={{ marginTop: '15px',textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: _selectionColor, fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: _buttonTextColor}}
            onClick={this.confirmBook}
            >

            {i18n.t('confirm')}</p>
                    </div>
                    </div>
                }
                
              
               
                    <br />
                    <br />
            </div>
        )
    }
}

export default Compliance
