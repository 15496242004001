import logo from './getfy.svg';
import './App.css';
import { Component } from 'react';
import Landing from './Landing';
import ComplianceManager from './ComplianceManager';
import RemoteCompliance from './RemoteCompliance';
import logoWhite from './logo-white.svg';
import logoBlack from './logo-black.svg';
import ThemeManager from './ThemeManager';
import MetaTags from 'react-meta-tags';

class App extends Component {
  
  constructor(props){
    super(props);
   
    
    //setInterval(() => this.showProducts(),10);
    var eventCode = window.location.href.replace(window.location.origin, "");
    eventCode = eventCode.replace("/", "");
   

    this.state = { eventCode: eventCode, token: '', notvalidevent: true, isReady: false, userInfo: null, bookingInfo: null, validity: null, generalInfo: []};
    
  }


  componentDidMount () {
    this.getEventInfo(this.state.eventCode);
  }

  getEventInfo = (code) => {

    
    var sessiontoken = localStorage.getItem('session_token');
    if(sessiontoken === null){
      sessiontoken = '';
    }
    
    const requestOptions = {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      body: JSON.stringify({
                code: code,
                token: sessiontoken
              })
      };
    
          const apiUrl = 'https://getfy-business.com/user/v5/code/compliance';

    fetch(apiUrl, requestOptions)
    .then(r => r.json())
    .then(r => {
      
      let themeManager = ThemeManager.getInstance();

      if(r.theme !== undefined && r.theme !== null){
        themeManager.setBackgroundColor(r.theme.background_color);
        themeManager.setSelectionColor(r.theme.selection_color);
        themeManager.setMainTextColor(r.theme.main_text_color);
        themeManager.setSecondaryTextColor(r.theme.secondary_text_color);
        themeManager.setButtonTextColor(r.theme.button_text_color);
        themeManager.setItemBackgroundColor(r.theme.item_background_color);
        themeManager.setErrorColor(r.theme.error_color);
      }

      var _backgroundColor = themeManager.getBackgroundColor();
      this.elaborateColorIntensity(_backgroundColor);

      var generalInfo = r.info; 
      var validity = r.info.details.validity;

      if(r.user == null){
        localStorage.removeItem('session_token');
        this.setState({ userInfo: null, notvalidevent: false, isReady: true, validity: validity, generalInfo: generalInfo});
      }else{
        var userInfo = [];
        userInfo.token = r.user.token;
        userInfo.birth = r.user.birth;
        userInfo.sex = r.user.sex;
        userInfo.name = r.user.name_array.name;
        userInfo.welcome = r.user.name_array.type;
        userInfo.info = r.user.info;
        
        var bookingInfo = null;
        if(r.booking!==null){
          bookingInfo = r.booking;
        }
        

        this.setState({ userInfo: userInfo, notvalidevent: false, isReady: true, bookingInfo: bookingInfo, validity: validity, generalInfo: generalInfo});
      }
      
    }).catch(e => {
      this.setState({ notvalidevent: true, isReady: true, validity: -1, bookingInfo: null});
    });
   
  }

  elaborateColorIntensity = (bgColor) => {
    return (parseInt(bgColor.replace('#', ''), 16) > 0xffffff / 2) ? this.setState({ logo: 'dark' }) : this.setState({ logo: 'light' });
  }
 

  render(){
    
    let themeManager = ThemeManager.getInstance();
    const _backgroundColor = themeManager.getBackgroundColor();
    const _selectionColor = themeManager.getSelectionColor();
    const _mainTextColor = themeManager.getMainTextColor();
    const _secondaryTextColor = themeManager.getSecondaryTextColor();
    const _itemBackgroundColor = themeManager.getItemBackgroundColor();
    const _errorColor = themeManager.getErrorColor();

    return (
      <div>
   { this.state.isReady &&
      <div className="App" style={{ backgroundColor: _backgroundColor}}>
     
      {this.state.isReady &&
      <MetaTags>
       <meta name="theme-color" content={_backgroundColor} />           
      </MetaTags>
      }
 
    

    
   {
      this.state.isReady && this.state.bookingInfo===null && this.state.validity !== 0 
      ? (
        <Landing validity={this.state.validity} />
         )
       : (
           null
          )
    }

    {
      this.state.isReady && this.state.generalInfo.type==='normal' && (this.state.validity === 0 || this.state.bookingInfo!==null)
      ? (
        <ComplianceManager user={this.state.userInfo} booking={this.state.bookingInfo} info={this.state.generalInfo} />
        )
       : (
           null
          )
    }

    {
      this.state.isReady && this.state.generalInfo.type==='remote' && (this.state.validity === 0 || this.state.bookingInfo!==null)
      ? (
        <RemoteCompliance user={this.state.userInfo} booking={this.state.bookingInfo} info={this.state.generalInfo} />
        )
       : (
           null
          )
    }

    <br /><br />

    { this.state.logo === 'dark' && 
    <img alt="Getfy" style={{marginTop: '30px', height: '18px'}} src={logoBlack} />
    }

    { this.state.logo === 'light' && 
    <img alt="Getfy" style={{marginTop: '30px', height: '18px'}} src={logoWhite} />
    }
    

</div>
  }
  </div>
  );
    }
}

export default App;
//<img src={logo} className="Getfy-logo" alt="logo" /> 