import React, { Component } from 'react'
import LangOption from './LangOption';
import Login from './Login/Login';
import PersonalData from './PersonalInfo/PersonalData';

export class UserManager extends Component {

    constructor(props){
        super(props);
        this.state = { userInfo: props.user, loginInfo: props.loginInfo };
        
    }


    render() {
        return (
            <div>
                 { this.state.userInfo === null && <Login info={this.state.loginInfo} /> }
                 { (this.state.userInfo === null || (this.state.userInfo.birth !== 'ok' || this.state.userInfo.sex !== 'ok')) && <LangOption /> }
               
              


                {
                   this.state.userInfo !== null && (this.state.userInfo.birth !== 'ok' || this.state.userInfo.sex !== 'ok')
                   ? (
                       <PersonalData user={this.state.userInfo} /> 
                   )
                   : (
                       null
                   )
               }                        

            </div>
        )
    }
}

export default UserManager
