import React, { Component } from 'react'
import QrReader from 'react-qr-reader'

export class DeclarationGP extends Component {

    constructor(props){
        super(props);

        
        this.state = { declaration: false, result: '', gpValid: false};

    }

    handleScan = data => {
        if (data) {
           
            this.checkGreenPass(data);
          
          /*  this.greenPassOk();
          this.setState({
            result: data,
            declaration: true,
            gpValid: true
          })*/
        }
      }
      handleError = err => {
        console.error(err)
      }
    
      openImageDialog() {
        this.refs.qrReader1.openImageDialog()
    }

    checkGreenPass = (code) => {



    var sessiontoken = localStorage.getItem('session_token');
    if(sessiontoken === null){
      sessiontoken = '';
    }
    
    const requestOptions = {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      body: JSON.stringify({
                code: code,
                token: sessiontoken
              })
      };
    
          const apiUrl = 'https://getfy-business.com/user/v5/greenpass/check';

    fetch(apiUrl, requestOptions)
    .then(r => r.json())
    .then(r => {
  
        this.setState({ declaration: true, gpValid: true, result: r.green_pass});
        this.greenPassOk();
      
    }).catch(e => {
        this.setState({ declaration: true, gpValid: false});
    });

    }


    greenPassOk = () => {
        this.props.setGP(true, '1');
    }

    render() {
        return (
            <div>
                
                <QrReader ref="qrReader1"
          delay={300}
          onError={this.handleError}
          onScan={this.handleScan}
          legacyMode={true}
          style={{ display: 'none', width: '20%' }}
        />
        <br />
                    { (this.state.declaration && !this.state.gpValid ) &&
                    <p style={{color: 'red', fontWeight: 'bold'}}>Green Pass NON Valido</p>
                    }
                    { (this.state.declaration && this.state.gpValid ) &&
                    <p style={{color: 'green', fontWeight: 'bold'}}>Green Pass Valido</p>
                    }

        { !this.state.gpValid  &&
        <div><div  style={{ marginTop: '25px',textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: 'green', fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}
            onClick={this.openImageDialog.bind(this)}
            >

                    Aggiungi Green Pass</p>
                    </div>
                    </div> 
            }
                   

            </div>
        )
    }
}

export default DeclarationGP
