import React, { Component } from 'react'
import logo from './getfy.svg';
import i18n from "i18next";

export class Landing extends Component {
    render() {
        return (
            <div>
                <img style={{marginTop: '50px', color: 'white'}} src={logo} />
                <div style={{marginTop: '50px', color: 'white'}}>{i18n.t('page.not.found')}</div>
            </div>
        )
    }
}

export default Landing
