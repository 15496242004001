import React, { Component } from 'react'
import UserManager from './UserManager';
import MainInfo from './MainInfo';
import Compliance from './Compliance';
import ComplianceDetails from './ComplianceDetails';
import UserProfile from './UserProfile';

export class ComplianceManager extends Component {

    constructor(props){
        super(props);
        
        var generalInfo = props.info;

        var codeInfo = generalInfo.type;
        var mainCode = generalInfo.maincode;
        
        var eventInfo = [];
        eventInfo.eventDate = generalInfo.details.date;
        eventInfo.clubName = generalInfo.details.place;
        eventInfo.eventName = generalInfo.details.name;
        eventInfo.hours = generalInfo.details.hours;
        eventInfo.hour = generalInfo.details.hour;
        eventInfo.dates = generalInfo.details.dates;
        eventInfo.eventDateArray = generalInfo.details.dateArray;

        var bookingInfo = null;
        if(props.booking!=null && codeInfo==='normal'){
            bookingInfo = props.booking;
        }
        console.log(props.user);
        this.state = { userInfo: props.user, bookingInfo: bookingInfo, eventInfo: eventInfo, type: codeInfo, mainCode: mainCode, userProfileOpened: false};
        
      }


      confirmAccess = (e) => {
          
        var sessiontoken = localStorage.getItem('session_token');
        if(sessiontoken === null){
            sessiontoken = '';
        }
    
    const requestOptions = {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      body: JSON.stringify({
                code: this.state.userInfo.token,
                token: sessiontoken
              })
      };
    
          const apiUrl = 'https://getfy-business.com/user/v5/compliance/';

    fetch(apiUrl, requestOptions)
    .then(r => r.json())
    .then(r => {

        window.location.reload();
      
    }).catch(e => {
        window.location.reload();
    });

      }


      openUserProfile = (open) => {
        this.setState({ userProfileOpened: open });
      }


    render() {
        return (
            <div>

                { !this.state.userProfileOpened && <MainInfo info={this.state.eventInfo} /> }


                {
                   !this.state.userProfileOpened && this.state.bookingInfo === null && this.state.userInfo !== null && this.state.userInfo.birth === 'ok' && this.state.userInfo.sex === 'ok'
                   ? (
                    <Compliance confirmAccess={this.confirmAccess} user={this.state.userInfo} />
                   )
                   : (
                       null
                   )
                }

                {
                   !this.state.userProfileOpened && this.state.bookingInfo !== null && this.state.userInfo !== null && this.state.userInfo.birth === 'ok' && this.state.userInfo.sex === 'ok'
                   ? (
                    <ComplianceDetails info={this.state.bookingInfo} user={this.state.userInfo}  />
                   )
                   : (
                       null
                   )
                }

                {
                   this.state.userInfo === null || (this.state.userInfo !== null && (this.state.userInfo.birth !== 'ok' || this.state.userInfo.sex !== 'ok'))
                   ? (
                    <UserManager user={this.state.userInfo} /> 
                   )
                   : (
                       null
                   )
                }

                {
                   this.state.userInfo !== null && this.state.userInfo.birth == 'ok' && this.state.userInfo.sex == 'ok'
                   ? (
                       <UserProfile open={this.openUserProfile} info={this.state.userInfo.info} /> 
                   )
                   : (

                       null
                   )
                }

            </div>
        )
    }
}

export default ComplianceManager
