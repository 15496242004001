import React, { Component } from 'react'
import complianceok from './isok.svg';
import ThemeManager from './ThemeManager';
import { BabelLoading, MeteorRainLoading } from 'react-loadingg';
import i18n from "i18next";

export class ComplianceDetails extends Component {

    intervalID = 0;

    constructor(props){
        super(props);
        
      
    
        this.state = { info: props.info, userInfo: props.user, time: new Date().toLocaleTimeString() };
        this.intervalID = setInterval(this.reloadTime, 1000);
      }

      reloadTime = () =>{
        this.setState({ time: new Date().toLocaleTimeString() });
      }

    render() {


        let themeManager = ThemeManager.getInstance();
    const _backgroundColor = themeManager.getBackgroundColor();
    const _selectionColor = themeManager.getSelectionColor();
    const _mainTextColor = themeManager.getMainTextColor();
    const _secondaryTextColor = themeManager.getSecondaryTextColor();
    const _itemBackgroundColor = themeManager.getItemBackgroundColor();
    const _buttonTextColor = themeManager.getButtonTextColor();
    const _errorColor = themeManager.getErrorColor();

        return (
            <div>
                 <div style={{marginTop: '20px', marginBottom: '0px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: _secondaryTextColor, fontSize: '14px'}}>
                 {i18n.t(this.state.userInfo.welcome)} <p style={{ color: _selectionColor, marginLeft: '8px', marginRight: '8px', fontSize: '16px', fontWeight: 'bold'}}>{this.state.userInfo.name}</p>
            </div>
                 <div style={{ fontSize: '14px', marginTop: '0px', color: _mainTextColor, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                 {i18n.t('confirmed.at')}
                    <p style={{ marginLeft:'8px', marginRight:'8px', color: _selectionColor, fontWeight: 'bold', fontSize: '16px'}}> 
                    {this.state.info.hour} 
                    </p>
                    
                    </div>
                    { this.state.info.validity != '' &&
                    <div>
                    <div style={{ fontSize: '14px', marginTop: '10px', color: _secondaryTextColor, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {i18n.t('valid.until')}  {this.state.info.validity}  
                    <br /><br />
                    
                    </div>
                    <div style={{ fontSize: '35px', marginTop: '15px', color: _secondaryTextColor, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {this.state.time}
                    </div>
                    </div>
                    }
           
           
           <BabelLoading size="large" color={_selectionColor} />
         
            
            
            </div>
        )
    }
}

export default ComplianceDetails
