import React, { Component } from 'react'
import PhoneNumber from './PhoneNumber';
import NumberConfirmation from './NumberConfirmation';
import Register from './Register';
import * as rdd from 'react-device-detect';

export class Login extends Component {

    constructor(props){
        super(props);

        var phoneNumber = '';
        var prefixNumber = '';
        var loginToken = '';
        var showConfirmation = false;
        var showPhoneNumber = true;
        var canCancel = true;

        if(props.info!=null){
            phoneNumber = props.info.phone;
            prefixNumber = props.info.prefix;
            loginToken = props.info.token;       
            
            showConfirmation = true;
            showPhoneNumber = false;
            canCancel = false;
        }
       

        this.state = { showPhoneNumber: showPhoneNumber, showConfirmation: showConfirmation, showName: false, phonenumber: phoneNumber, prefix: prefixNumber, token: loginToken, coderror: false, canCancel: canCancel};
        
    }


    doLogin = (number, prefix) => {
        
        const requestOptions = {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify({
                phone: number,
                prefix: prefix
              })
            };
          
                const apiUrl = 'https://getfy-business.com/user/v5/login/';
      
          fetch(apiUrl, requestOptions)
          .then(r => r.json())
          .then(r => {
            this.setState({ showPhoneNumber: false, showConfirmation: true, phonenumber: number, prefix: prefix, token: r.token});
          }).catch(e => {
            window.location.reload();
          });
    }

    showLogin = (e) => {
        this.setState({ showPhoneNumber: true, showConfirmation: false, showName: false, phonenumber: '', prefix: ''});
    }

    verifyCode = (code) => {
        this.setState({ coderror: false });
        var datainfo = rdd.osName+'-'+rdd.osVersion+'-'+rdd.fullBrowserVersion+'-'+rdd.browserName+'-'+rdd.mobileVendor+'-'+rdd.mobileModel+'-'+rdd.getUA+'-'+rdd.deviceType;
        const requestOptions = {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify({
                token: this.state.token,
                code: code,
                info: datainfo
              })
            };
          
        const apiUrl = 'https://getfy-business.com/user/v5/login/code';
      
          fetch(apiUrl, requestOptions)
          .then(r => r.json())
          .then(r => {
            if(r.info===0){
               localStorage.setItem('session_token', r.token);
               window.location.reload();
            }else{
                this.setState({ showPhoneNumber: false, showConfirmation: false, showName: true, token: r.token});
            }
            
          }).catch(e => {
              this.setState({ coderror: true});
          });
       
    }

    registerNow = (name,surname) => {
        this.setState({ coderror: false });
        var datainfo = rdd.osName+'-'+rdd.osVersion+'-'+rdd.fullBrowserVersion+'-'+rdd.browserName+'-'+rdd.mobileVendor+'-'+rdd.mobileModel+'-'+rdd.getUA+'-'+rdd.deviceType;
        const requestOptions = {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify({
                token: this.state.token,
                name: name,
                surname: surname,
                info: datainfo
              })
            };
          
                const apiUrl = 'https://getfy-business.com/user/v5/login/register';
      
          fetch(apiUrl, requestOptions)
          .then(r => r.json())
          .then(r => {
            
            localStorage.setItem('session_token', r.token);
            window.location.reload();
     
          }).catch(e => {
              this.setState({ coderror: true});
          });
    }


    render() {
        return (
            <div>
                

                {
                    this.state.showPhoneNumber
                    ? (
                        <PhoneNumber login={this.doLogin} />
                    )
                    : (
                        null
                    )
                }


                {
                    this.state.showConfirmation
                    ? (
                        <NumberConfirmation error={this.state.coderror} cancel={this.showLogin} number={this.state.phonenumber} prefix={this.state.prefix} verify={this.verifyCode} canCancel={this.state.canCancel} />
                    )
                    : (
                        null
                    )
                }


                {
                    this.state.showName
                    ? (
                        <Register error={this.state.coderror} cancel={this.showLogin} register={this.registerNow} />
                    )
                    : (
                        null
                    )
                }

            </div>
        )
    }
}

export default Login
