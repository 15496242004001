import React, { Component } from 'react'
import ThemeManager from './ThemeManager';
import i18n from "i18next";

export class DeclarationBasic extends Component {

    constructor(props){
        super(props);

        this.state = { declaration: false, animationShake: ''};

    }

    checkboxAction = (e) => {

        if(!this.state.declaration){
            this.setState({ declaration: true}); 
            this.props.showConfirm(true);
        }else{
            this.setState({ declaration: false});  
            this.props.showConfirm(false);
        }
    }

    closeAnimation = (e) => {
        this.setState({ animationShake: ''}); 
        clearInterval(this.intervalID);
    }

    render() {


        let themeManager = ThemeManager.getInstance();
    const _backgroundColor = themeManager.getBackgroundColor();
    const _selectionColor = themeManager.getSelectionColor();
    const _mainTextColor = themeManager.getMainTextColor();
    const _secondaryTextColor = themeManager.getSecondaryTextColor();
    const _itemBackgroundColor = themeManager.getItemBackgroundColor();
    const _buttonTextColor = themeManager.getButtonTextColor();
    const _errorColor = themeManager.getErrorColor();


        return (
            <div>
                <div style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '300px', marginTop: '20px', fontSize: '14px', color: _mainTextColor}}>
                <p style={{ color: _mainTextColor, fontWeight: 'bold'}}>{i18n.t('covid.check')}</p>
                <p style={{ color: _secondaryTextColor, fontWeight: 'normal', fontSize: '12px'}}>
                {i18n.t('covid.text')}<br />  
                <a target="_blank" style={{ marginLeft: '5px', fontWeight: 'bold', color: _selectionColor, textDecoration: 'none'}} href="https://www.getfy.it/agreements/covid-policy.pdf">
                {i18n.t('this.link')}
                </a>
                </p>
                <div style={{ marginTop: '25px', color: _mainTextColor, fontWeight: 'bold', fontSize: '12px'}}>{i18n.t('declare')}  
                <div><input className={this.state.animationShake} style={{marginTop: '3px'}} checked={this.state.declaration} onChange={this.checkboxAction} type="checkbox" /> </div>
                </div>
                </div>
            </div>
        )
    }
}

export default DeclarationBasic
