import React, { Component } from 'react'
import ThemeManager from './ThemeManager';
import i18n from "i18next";
import Drawer from 'react-drag-drawer';
import './Popupstyle.css';
import i18next from 'i18next';
import { BoxLoading, CoffeeLoading } from 'react-loadingg';

export class UserProfile extends Component {

    constructor(props){
        super(props);
        
        var generalInfo = props.info;
        var currentLang = i18next.language;
        var currentLangSymbol = '🇬🇧';
        if(currentLang == 'it'){
            currentLangSymbol = '🇮🇹';
        }else if(currentLang == 'ja'){
            currentLangSymbol = '🇯🇵';
        }else if(currentLang == 'es'){
            currentLangSymbol = '🇪🇸';
        }

        this.state = { currentLang: currentLang, currentLangSymbol: currentLangSymbol, info: generalInfo, nameValue: generalInfo.name, surnameValue: generalInfo.surname, 
            isExpanded: false, showConfirm: false, isProcessing: false, changeImage: false, doLogout: false, changeLang: false, waitingUpload: false };
        this.props.open(false);
    }


    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    insertData = (e) => {
        var insert = e.target.value;
        insert =  this.capitalizeFirstLetter(insert);
        this.setState({ [e.target.name] : insert});

        if(e.target.name == 'nameValue'){
            this.checkInfoChanged(insert, this.state.surnameValue);
        }else{
            this.checkInfoChanged( this.state.nameValue, insert);
        }
        
    }

    expandUserProfile = () => {
        this.setState({ isExpanded: true });
        this.props.open(true);
    }

    hideUserProfile = () => {
        this.setState({ isExpanded: false, nameValue: this.state.info.name, surnameValue: this.state.info.surname });
        this.props.open(false);
    }

    checkInfoChanged = (name, surname) => {
        if((this.state.info.name!=name || this.state.info.surname!=surname) && name!="" && surname!=""){
            this.setState({ showConfirm: true });
        }else{
            this.setState({ showConfirm: false });
        }
    }

    regenrateProfilePicture = () => {
        
        if(!this.state.isProcessing){
            this.setState({ isProcessing: true, waitingUpload: true });
           

            var sessiontoken = localStorage.getItem('session_token');
            if(sessiontoken === null){
                sessiontoken = '';
            }

            const requestOptions = {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/x-www-form-urlencoded',
                }),
                body: JSON.stringify({
                    token: sessiontoken
                  })
                };

                
                const apiUrl = 'https://getfy-business.com/user/v5/user/regenerate-image';
          
              fetch(apiUrl, requestOptions)
              .then(r => r.json())
              .then(r => {
               
                window.location.reload();
         
              }).catch(e => {
                window.location.reload();
              });
        }
    }

    confirmChange = () => {
        if(!this.state.isProcessing){
            this.setState({ isProcessing: true, waitingUpload: true});
           

            var sessiontoken = localStorage.getItem('session_token');
            if(sessiontoken === null){
                sessiontoken = '';
            }

            const requestOptions = {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/x-www-form-urlencoded',
                }),
                body: JSON.stringify({
                    token: sessiontoken,
                    name: this.state.nameValue,
                    surname: this.state.surnameValue,
                  })
                };

                
                const apiUrl = 'https://getfy-business.com/user/v5/user/set/name';
          
              fetch(apiUrl, requestOptions)
              .then(r => r.json())
              .then(r => {
               
                window.location.reload();
         
              }).catch(e => {
                window.location.reload();
              });
        }
    }


    changeImage = () => {
        this.setState({ changeImage: true });
    }

    toggle = () => {
        this.setState({ changeImage: false, doLogout: false, changeLang: false });
    }


    onChangeFile = (event) => {
        event.stopPropagation();
        event.preventDefault();
        var file = event.target.files[0];
        
        this.sendUserImage(file);
    }

    sendUserImage = (image) => {
        if(!this.state.isProcessing){
            this.setState({ isProcessing: true, waitingUpload: true});           

            var sessiontoken = localStorage.getItem('session_token');
            if(sessiontoken === null){
                sessiontoken = '';
            }

            var data = new FormData();
            data.append('file', image);
            data.append('token', sessiontoken);

            const requestOptions = {
                method: 'POST',
                body: data
                };
               
            const apiUrl = 'https://getfy-business.com/user/v5/user/upload-image';
          
            fetch(apiUrl, requestOptions)
              .then(r => r.json())
              .then(r => {
               
                window.location.reload();
         
              }).catch(e => {
                window.location.reload();
              });
        }
    }

    uploadImage = () => {
        this.refs.fileUploader.click();
    }

    logOut = () => {
        this.setState({ doLogout: true });
    }

    changeLang = () => {
        this.setState({ changeLang: true });
    }

    setLang = (lang) => {
        i18next.changeLanguage(lang);
        window.location.reload();
    }


    confirmLogout = () => {
        if(!this.state.isProcessing){
            this.setState({ isProcessing: true, waitingUpload: true });
           

            var sessiontoken = localStorage.getItem('session_token');
            if(sessiontoken === null){
                sessiontoken = '';
            }

            const requestOptions = {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/x-www-form-urlencoded',
                }),
                body: JSON.stringify({
                    token: sessiontoken
                  })
                };

                
                const apiUrl = 'https://getfy-business.com/user/v5/login/logout';
          
              fetch(apiUrl, requestOptions)
              .then(r => r.json())
              .then(r => {
               
                window.location.reload();
         
              }).catch(e => {
                window.location.reload();
              });
        }
    }

  render() {

    let themeManager = ThemeManager.getInstance();
        const _backgroundColor = themeManager.getBackgroundColor();
        const _selectionColor = themeManager.getSelectionColor();
        const _mainTextColor = themeManager.getMainTextColor();
        const _secondaryTextColor = themeManager.getSecondaryTextColor();
        const _buttonTextColor = themeManager.getButtonTextColor();
        const _itemBackgroundColor = themeManager.getItemBackgroundColor();
        const _errorColor = themeManager.getErrorColor();

    return (
      <div>

        { !this.state.isExpanded &&
        <div style={{ position: 'absolute', right: '10px', top: '10px' }}>
        <img onClick={this.expandUserProfile} style={{ width: '35px', height: '35px', borderRadius: '50%', cursor: 'pointer' }} src={this.state.info.user_photo} />       
        </div>
        }

        { this.state.isExpanded &&
        <div style={{ marginTop: '30px' }}>

        <div style={{  display: 'flex', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>    
        <div style={{ display: 'flex', width: '250px' }}>
            <div style={{ width: '50%'}}>
            <div onClick={this.changeLang} style={{ width: '50px', cursor: 'pointer', textAlign: 'left', marginTop: '0px', color: _secondaryTextColor, fontSize: '20px', fontWeight: 'normal'}}>
            {this.state.currentLangSymbol}
            </div>
            </div>
            <div style={{ width: '50%', display: 'flex', alignItems: 'right', justifyContent: 'right'}}>
            <div onClick={this.logOut} style={{ width: '60px', cursor: 'pointer', textAlign: 'right', marginTop: '5px', color: _secondaryTextColor, fontSize: '12px', fontWeight: 'normal'}}>
            {i18n.t('logout')}
            </div>
            </div>
        </div>
        </div>


        <img style={{ marginTop: '20px', width: '60px', height: '60px', borderRadius: '50%', cursor: 'pointer' }} src={this.state.info.user_photo} />   
        <div onClick={this.changeImage} style={{ cursor: 'pointer', marginTop: '5px', color: _secondaryTextColor, fontSize: '10px', fontWeight: 'bold'}}>{i18n.t('change.image')}</div>


        <div style={{marginTop: '30px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
             
                <div style={{ height: '45px', borderRadius: '8px', justifyContent: 'center', alignItems: 'center'}}>
                <input 
                style={{ border: 'none', borderRadius: '8px', fontWeight: 'normal', color: _mainTextColor, height: '45px', width: '250px',
                 outlineWidth: '0',backgroundColor: _itemBackgroundColor, fontSize: '14px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} type="text" 
                name="nameValue"
                value={this.state.nameValue}
                placeholder={i18n.t('name')}
                onChange={this.insertData}
                />
                </div>
                
            </div>

            <div style={{marginTop: '15px', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
             
                <div style={{ height: '45px', borderRadius: '8px', justifyContent: 'center', alignItems: 'center'}}>
                <input 
                style={{ border: 'none', borderRadius: '8px', fontWeight: 'normal', color: _mainTextColor, height: '45px', width: '250px',
                 outlineWidth: '0',backgroundColor: _itemBackgroundColor, fontSize: '14px', textAlign: 'center'}} type="text" 
                name="surnameValue"
                value={this.state.surnameValue}
                placeholder={i18n.t('surname')}
                onChange={this.insertData}
                />
                </div>
                
            </div>

            {this.state.showConfirm &&
            <div  style={{marginTop: '20px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: _selectionColor, fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: _buttonTextColor }}
            onClick={this.confirmChange}
            >
                    {i18n.t('confirm')}</div>
            </div>
            }
            <br />
            <div onClick={this.hideUserProfile} style={{cursor: 'pointer', fontSize: '14px', color: 'rgb(60,60,60)'}}>{i18n.t('back')}</div>
            
            <br />
            <br />

            <input type="file" id="file" accept="image/png, image/jpeg" ref="fileUploader" 
   onChange={this.onChangeFile.bind(this)} style={{display: "none"}}/>

        </div>
        }

<Drawer
                    open={this.state.changeImage}
                        onRequestClose={this.toggle}
                        modalElementClass="modalPrefix">
                            <div>
                    
                            <div  style={{marginTop: '20px',  textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: _selectionColor, fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: _buttonTextColor}}
            onClick={this.regenrateProfilePicture}
            >{i18n.t('reset.image')}</p>
                    </div>

                    <div  style={{marginTop: '-5px',  textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: _selectionColor, fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: _buttonTextColor}}
            onClick={this.uploadImage}
            >{i18n.t('upload.image')}</p>
                    </div>

                    <div  style={{ marginTop: '5px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div onClick={this.toggle} style={{cursor: 'pointer', fontSize: '14px', color: 'rgb(60,60,60)'}}>{i18n.t('back')}</div>
                    </div>
                    </div>
            </Drawer>

            <Drawer
                    open={this.state.waitingUpload}
                        modalElementClass="modalLang">
                        <div>                    
                        
                        <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center' }}>
                        <BoxLoading size="large" color={_selectionColor} />
         
                        <br />
                        <br />
                        <br />
                        </div>
                    </div>
            </Drawer>


            <Drawer
                    open={this.state.changeLang}
                        onRequestClose={this.toggle}
                        modalElementClass="modalLang">
                        <div>                    
                        
                        <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center' }}>
                        
                        <div onClick={() => this.setLang('it')} style={{ padding: '20px', width: '150px', cursor: 'pointer', display: 'flex', textAlign: 'center', marginTop: '0px', color: 'rgb(60,60,60)', fontSize: '20px', fontWeight: 'normal'}}>
                        🇮🇹 <p style={{ marginLeft: '10px', marginTop: '6px', fontSize: '12px' }}>Italiano</p>
                        </div>

                        <div onClick={() => this.setLang('en')} style={{  width: '150px', cursor: 'pointer', display: 'flex', textAlign: 'center', marginTop: '0px', color: 'rgb(60,60,60)', fontSize: '20px', fontWeight: 'normal'}}>
                        🇬🇧 <p style={{ marginLeft: '10px', marginTop: '6px', fontSize: '12px' }}>English</p>
                        </div>

                        </div>

                        <div style={{ marginTop: '-35px', display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center' }}>
                        
                        <div onClick={() => this.setLang('ja')} style={{ padding: '20px', width: '150px', cursor: 'pointer', display: 'flex', textAlign: 'center', marginTop: '0px', color: 'rgb(60,60,60)', fontSize: '20px', fontWeight: 'normal'}}>
                        🇯🇵 <p style={{ marginLeft: '10px', marginTop: '6px', fontSize: '12px' }}>日本</p>
                        </div>

                        <div onClick={() => this.setLang('es')} style={{  width: '150px', cursor: 'pointer', display: 'flex', textAlign: 'center', marginTop: '0px', color: 'rgb(60,60,60)', fontSize: '20px', fontWeight: 'normal'}}>
                        🇪🇸 <p style={{ marginLeft: '10px', marginTop: '6px', fontSize: '12px' }}>Español</p>
                        </div>

                        </div>

                    <div  style={{ marginTop: '-20px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div onClick={this.toggle} style={{cursor: 'pointer', fontSize: '14px', color: 'rgb(60,60,60)'}}>{i18n.t('back')}</div>
                    <br />
                    </div>
                    </div>
            </Drawer>



                    <Drawer
                    open={this.state.doLogout}
                        onRequestClose={this.toggle}
                        modalElementClass="modalPrefix">
                            <div>
                            <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{marginTop: '20px', fontSize: '14px', color: 'rgb(60,60,60)' }}>{i18n.t('want.to')} <br />{i18n.t('do.logout')}</div>
                    </div>
                          
                    <div  style={{marginTop: '20px',  textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: _selectionColor, fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: _buttonTextColor}}
            onClick={this.confirmLogout}
            >{i18n.t('logout')}</p>
                    </div>

                    <div  style={{ marginTop: '5px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div onClick={this.toggle} style={{cursor: 'pointer', fontSize: '14px', color: 'rgb(60,60,60)'}}>{i18n.t('back')}</div>
                    </div>
                    </div>
                    </Drawer>


      </div>
    )
  }
}

export default UserProfile